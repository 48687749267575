<template>
  <ConfirmationModal 
    :title="currentConfirmationData.title"
    :text="currentConfirmationData.text"
    :visible="currentConfirmationData.visible"
    @emitResponse="applyToJob"
  />
  <LoadingDialog 
    :visible="loadingModal"
  />
  <div 
    v-if="!props.card && !isLoading()" 
    :style="{'margin-top': marginOffset}"
    class="w-3/4 mx-auto"
  >
    <Button v-if="showGoBackButton" class="p-button p-component text-oxford-blue rounded-lg py-2" @click.prevent="returnToJobList()">
      <i class="pi pi-arrow-left text-lg me-2"></i> {{ translate('go back', 'ucfirst') }}
    </Button>
  </div>
  <section 
    :style="props.card || isLoading() ? {'margin-top': marginOffset, 'margin-bottom': marginOffset} : {'margin-top': marginOffsetReduced, 'margin-bottom': marginOffset}"
    :class="job !== undefined && job !== null ? props.class : ''"
  >
    <div v-if="loading" class="w-full text-center p-5 h-1/2">
      <LoaderComponent />
    </div>
    <div 
        v-if="job !== undefined && job !== null && !isLoading()"
    >
        <div :class="props.card ? 'w-full text-right absolute pr-5 pt-5' : 'w-full md:w-2/4 text-right absolute pr-5 pt-5'">
          <Button 
            class="text-white p-2 bg-primary-color rounded cursor-pointer"
            icon="pi pi-share-alt"
            :label="translate('share', 'ucfirst')"
            @click="openSharer()"
          />
        </div>
        <!-- Company cover photo -->
        <div
          :style="{ backgroundImage: `url(${job.company_cover_photo})`}"
          class="w-full h-40 bg-slate-400 rounded-t-lg"
        ></div>

        <!-- Company logo -->
        <div
          :style="{ backgroundImage: `url(${job.company_logo})`}"
          class="w-32 h-32 mx-auto -mt-14 rounded-full bg-slate-600 border-2 border-white profile-photo"
        ></div>

        <!-- Job title -->
        <div class="flex flex-col text-center py-5">
          <div class="flex justify-between mb-5" v-if="!applianceStatus.canApply && store.getters.isLoggedIn && store.getters.loggedAsProfessional">
            <p class="text-left px-5 ft-sea-serpent" style="color: #53c3c4;">
              <i class="pi pi-check-circle" style="font-size: 0.95rem;"></i>
              <span class="pl-2">{{ translate('already applied', 'upper') }}</span>
            </p>
            <p class="font-bold px-5 text-oxford-blue ">
              {{ `${translate('applied at', 'ucfirst')}:` }} 
              {{ applianceStatus.appliedAt }}
            </p>
          </div>
          <h1 class="text-2xl font-bold px-5 text-oxford-blue cursor-pointer">
            {{ ucfirst(job.job_title) }} 
          </h1>
          <div class="flex justify-center gap-x-3 px-3">
            <span class="flex items-center text-sm"
              ><i class="pi pi-building mr-1" style="font-size: 0.95em"></i> {{ ucfirst(job.company_name) }}</span
            >
            <span class="flex items-center text-sm"
              ><i class="pi pi-clock mr-1" style="font-size: 0.95em"></i> {{ translate('at', 'ucfirst') + " " + job.posted_at }}</span
            >
          </div>
          <div>
            <Button
              v-if="applianceStatus.canApply"
              @click.prevent="tryToApply()"
              class="text-white py-1.5 px-9 bg-sea-serpent mt-5 md:w-2/4 w-3/4 m-auto"
              :style="{'opacity': (!store.getters.isLoggedIn || store.getters.loggedAsProfessional) ? '' : '0.5'}"
              :label="translate('apply', 'ucfirst')"
            />
            <Button
              v-if="!applianceStatus.canApply"
              @click.prevent="openModal()"
              class="text-white py-1.5 px-9 bg-sea-serpent mt-5 md:w-2/4 w-3/4 m-auto"
              :label="translate('see contact information', 'ucfirst')"
            />
          </div>
          <div
            class="flex justify-between items-center mt-5 px-4 pb-2 border-b border-iron"
          >
            <p class="text-lg font-semibold text-oxford-blue">
              <i class="pi pi-money-bill mr-2"></i> {{ job.minimunWage == 0 && job.maxWage == 0 ? translate('not specified', 'ucfirst') : job.salary }}
            </p>
          </div>
        </div>
        <!-- Job content -->
        <div class="px-6">
          <h3 class="my-3 font-semibold text-oxford-blue many-wrapper-section">
            <i :class="jobInformations.manySection.icon" :style="jobInformations.manySection.style"></i> {{ jobInformations.manySection.title }}:
          </h3>
          <div v-for="infoData, index in jobInformations.many" :key="index" class="pl-3 pe-3 many-wrapper-section">
            <h3 
              :hidden="job[infoData.attr] == undefined || job[infoData.attr].length < 1" 
              class="many-info-title my-3 font-semibold text-oxford-blue"
            >
              {{ infoData.title }}:
            </h3>
            <p v-if="job[infoData.attr]" class="many-info-content">
              <span 
                v-for="value, index in job[infoData.attr]"
                :key="index"
                
                :class="`an-option opt-${infoData.attr} ${index > (infoData.limit - 1) ? '' : 'opt-fixed'}`"
              >
                <span 
                  :hidden="job[infoData.attr].length > infoData.limit"
                  class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center pointer-none mt-2 ml-2 me-2 mb-2"
                >
                  {{ value }}
                </span>
              </span>
              <span 
                v-if="job[infoData.attr].length > infoData.limit && job[infoData.attr].length - infoData.limit > 0" 
                @click="(event) => handleManyJobInformation(infoData.attr, event)"
                class="underline cursor-pointer"
              >
                + {{ job[infoData.attr].length - infoData.limit }} {{ job[infoData.attr].length - infoData.limit == 1 ? infoData.oneMore : infoData.moreThanOne }}
              </span>
            </p>
          </div>
          <hr class="mt-3 border-b many-wrapper-section">

          <h3 class="my-3 font-semibold text-oxford-blue one-wrapper-section">
            <i :class="jobInformations.oneSection.icon" :style="jobInformations.oneSection.style"></i> {{ jobInformations.oneSection.title }}:
          </h3>
          <div v-for="infoData, index in jobInformations.one" :key="index" class="pl-3 pe-3 one-info-content">
            <template v-if="job[infoData.attr]">
              <h4 
                v-if="infoData.attr == 'experience_required' && job['experience_in_months'] < 1 ? false : true"
                class="my-3 font-semibold text-oxford-blue"
              >
                {{ infoData.title }}:
              </h4>
              <p 
                v-if="infoData.attr == 'experience_required' && job['experience_in_months'] < 1 ? false : true"
                class="bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
              >
                {{ ucfirst(job[infoData.attr]) }}
              </p>
            </template>
          </div>
          <hr class="mt-3 border-b one-wrapper-section">

          <h3 class="my-3 font-semibold text-oxford-blue">{{ translate('location', 'ucfirst') }}:</h3>
          <p>
            <i class="pi pi-map-marker me-2" style="font-size: 1.3rem;"></i>{{ job.full_location }}
          </p>

          <h3 class="my-3 font-semibold text-oxford-blue" v-if="job.job_description">{{ translate('job description', 'ucfirst') }}:</h3>
          <p v-if="job.job_description">
            {{ ucfirst(job.job_description) }}
          </p>

          <h3 class="my-3 font-semibold text-oxford-blue" v-if="job.job_experience_description">{{ translate('activities', 'ucfirst') }}:</h3>
          <p v-if="job.job_experience_description">
            {{ job.job_experience_description }}
          </p>

          <h3 class="my-3 font-semibold text-oxford-blue" v-if="job.job_requirements">{{ translate('requirements', 'ucfirst') }}:</h3>
          <p v-if="job.job_requirements">
            {{ job.job_requirements }}
          </p>

          <h3 class="my-3 font-semibold text-oxford-blue" v-if="job.job_offer">{{ translate('benefits', 'ucfirst') }}:</h3>
          <p v-if="job.job_offer">
            {{ job.job_offer }}
          </p>
        </div>

      <!-- Contact modal -->
      <Dialog 
        v-model:visible="showModal" 
        modal 
        :closable="true"
        :header="`${translate('send your curriculum or contact throught one of the following', 'ucfirst')}:`"
        :style="{ width: '35rem' }"
        class="ml-2 me-2"
      >
        <p v-if="contact.contactName" class="mb-2">
          <strong>{{ translate('contact name', 'ucfirst') }}:</strong> 
          <i class="ms-2 pi pi-tag"></i>
          {{ contact.contactName }}
        </p>
        <p v-if="contact.contactEmail" class="mb-2">
          <strong>{{ translate('email', 'ucfirst') }}:</strong> 
          <a :href="`mailto: ${contact.contactEmail}`" target="_blank" class="cursor-pointer underline text-blue-600 hover:text-blue-600 visited:text-blue-800"> 
            <i class="ms-2 pi pi-envelope"></i> {{ contact.contactEmail }}
          </a>
        </p>
        <p v-if="contact.contactPhone" class="mb-2">
          <strong>{{ translate('contact number', 'ucfirst') }}:</strong> 
          <a :href="`tel: ${contact.contactPhone}`" target="_blank" class="cursor-pointer underline text-blue-600 hover:text-blue-600 visited:text-blue-800"> 
            <i class="ms-2 pi pi-phone"></i> {{ contact.phoneDDI }} {{ contact.contactPhone }}
          </a>
        </p>
        <p v-if="contact.contactWebSite" class="mb-2">
          <strong>{{ translate('contact website', 'ucfirst') }}:</strong> 
          <a :href="contact.contactWebSite" target="_blank" class="cursor-pointer underline text-blue-600 hover:text-blue-600 visited:text-blue-800"> 
            <i class="ms-2 pi pi-link"></i> {{ contact.contactWebSite }}
          </a>
        </p>
      </Dialog>
    </div>
  </section>
  

  <Dialog
    v-model:visible="sharerData.visible" 
    modal 
    :closable="true"
    :draggable="false"
    :header="`${translate('share job', 'ucfirst')}`"
    :style="{ width: '35rem' }"
    class="ml-2 me-2"
  >
    <Sharer 
      :link="sharerData.link"
      :title="sharerData.title"
      :description="sharerData.description"
    />
  </Dialog>
</template>

<script setup>
  import { onMounted, onUnmounted, reactive, ref, watch } from "vue";
  import store from "@/store";
  import { translate } from "@/composables/translate";
  import { useToast } from "primevue/usetoast";
  import { showToast } from "@/composables/toast";
  import { useRoute } from 'vue-router';
  import { ucfirst } from "@/composables/textUtils";
  import requestHandler from "@/composables/requestHandler";
  import jobData from "@/composables/jobData";
  import LoaderComponent from "../Utils/LoaderComponent.vue";
  import Button from "primevue/button";
  import { sleep } from "@/composables/sleep";
  import utils from "@/composables/utils";
  import Dialog from "primevue/dialog";
  import ConfirmationModal from "../Utils/ConfirmationModal.vue";
  import professionalData from "@/composables/professionalData";
  import LoadingDialog from "../Utils/LoadingDialog.vue";
  import Sharer from "@/components/SharerComponent.vue";
  import { env } from "@/composables/config";

  const props = defineProps({
    job: {
      type: Object,
      required: false,
      default: null
    },
    class: {
      type: String,
      required: false,
      default: 'md:w-2/4 mx-auto rounded overflow-hidden shadow-lg bg-white overflow-y-auto pb-5'
    },
    showLoader: {
      type: Boolean,
      required: false,
      default: true
    },
    card: {
      type: Boolean,
      required: false,
      default: false
    },
    goBackButton: {
      type: Boolean,
      required: false,
      default: false
    }
  });

  let marginOffsetReduced = ref('0px');
  let marginOffset = ref('0px')
  let loading = ref(false);
  let job = ref();
  let showGoBackButton = ref(false);
  watch(() => props.job, (newValue) => {
    job.value = newValue;
    if(job.value != undefined && job.value.job_id != undefined)
      checkAppliance(job.value.job_id);
  });

  watch(() => props.goBackButton, (newValue) => {
    showGoBackButton.value = newValue;
  });

  const route = useRoute();
  const toast = useToast();
  onMounted(function(){
    showGoBackButton.value = props.goBackButton;
    if(!props.card){
      checkHeight();
      window.addEventListener('resize', checkHeight());
    }else{
      loading.value = false;
    }
    if(!props.showLoader)
      loading.value = false;
    if((route.params == undefined || route.params.job_id == undefined) && store.getters.getOnDataChest('job_details_view_selected_job').length < 1){
      jobNotFound();
    }else{
      getJobById();
    }
    hideManyOptionExcess();
    if(job.value == undefined && props.job != null)
      job.value = props.job;
    if(job.value != undefined)
      checkAppliance(job.value.job_id);
  });

  onUnmounted(function(){
    job.value = null;
  });

  function checkHeight(){
    let els = {
      webNavbar: document.querySelector('.webmenu-navbar').offsetHeight,
      mobileNavbar: document.querySelector('.mobile-navbar').offsetHeight
    }
    let navBarHeight = 0;
    if(els.webNavbar == 0){
      navBarHeight = els.mobileNavbar;
    }else{
      navBarHeight = els.webNavbar;
    }
    
    navBarHeight = (navBarHeight * 3) / 2; // 2/3
    marginOffsetReduced.value = (navBarHeight / 3).toString() + 'px';
    marginOffset.value = navBarHeight.toString() + 'px';
  }

  async function jobNotFound(){
    if(!props.card)
      store.dispatch('saveSessionMessage', {message: translate('job not found'), messageType: 'error'});
    await requestHandler.redirect('/jobs');
  }

  async function getJobById(){
    let jobId = route.params.job_id != undefined ? route.params.job_id : null;
    if(props.job != null){
      job.value = props.job;
      return;
    }
    loading.value = true;
    let cachedJob = store.getters.getOnDataChest(`save_job_${jobId}`);
    if(cachedJob == null || cachedJob.length < 1){
      const response = await jobData.searchForJob(jobId);
      if(!response.success || !response.data.data.length < 1)
        await jobNotFound();
      job.value = jobData.readJobImages(response.data.data);
      store.dispatch('saveOnDataChest', {
        key: `save_job_${jobId}`,
        value: job.value
      });
    }else{
      job.value = cachedJob;
    }
    hideManyOptionExcess();
    loading.value = false;
    checkAppliance(job.value.job_id);
  } 

  function isLoading(){
    if(props.showLoader){
      return loading.value;
    }else{
      return false;
    }
  }

  const jobInformations = {
    manySection: {title: translate('about the job', 'ucfirst'), icon: 'pi pi-info-circle', style: 'font-size: 1.3rem;', values: 'many'},
    manyShowing: {skills: false, languages: false, visasNames: false, jobDrivingLicensesNames: false, certifications: false},
    formerData: {},
    many: [
      {
        attr: 'skills', limit: 3, title: translate('skills', 'ucfirst'), oneMore: translate('skill'), 
        moreThanOne: translate('skills'), icon: ''
      },
      {
        attr: 'languages', limit: 3, title: translate('languages', 'ucfirst'), oneMore: translate('language'), 
        moreThanOne: translate('languages'), icon: ''
      },
      {
        attr: 'visas', limit: 3, title: translate('visas', 'ucfirst'), oneMore: translate('visa'), 
        moreThanOne: translate('visas'), icon: ''
      },
      {
        attr: 'jobDrivingLicensesNames', limit: 3, title: translate('driving licenses', 'ucfirst'), oneMore: translate('driving license'), 
        moreThanOne: translate('driving licenses'), icon: ''
      },
      {
        attr: 'certifications', limit: 3, title: translate('certifications', 'ucfirst'), oneMore: translate('certification'), 
        moreThanOne: translate('certifications'), icon: ''
      },
    ],
    oneSection: {title: translate('details', 'ucfirst'), icon: 'pi pi-briefcase', style: 'font-size: 1.3rem;', values: 'one'},
    one: [
      {attr: 'job_modality', title: translate('job modality', 'ucfirst')},
      {attr: 'profession_for_job', title: translate('profession', 'ucfirst')},
      {attr: 'job_contract', title: translate('job contract', 'ucfirst')},
      {attr: 'job_period', title: translate('job period', 'ucfirst')},
      {attr: 'working_visa', title: translate('visa option', 'ucfirst')},
      {attr: 'wage_currency', title: translate('currency of wage', 'ucfirst')},
      {attr: 'experience_required', title: translate('experience required', 'ucfirst')},
      {attr: 'job_seniority', title: translate('seniority', 'ucfirst')}
    ]
  };

  async function returnToJobList(){

    await requestHandler.redirect('/jobs');
  }

  function handleManyJobInformation(id, event){
    let elements = document.querySelectorAll(`.opt-${id}`);
    jobInformations.manyShowing[id] = jobInformations.manyShowing[id] ? false : true;
    if(jobInformations.formerData[id] == undefined)
      jobInformations.formerData[id] = event.target.textContent;
    for(let el of elements){
      if(el.classList.contains('opt-fixed'))
        continue;
        if(jobInformations.manyShowing[id]){
        event.target.textContent = `- ${translate('show less')}`;
        el.removeAttribute('hidden');
        }else{
        event.target.textContent = jobInformations.formerData[id];
        el.setAttribute('hidden', true);
      }
    }
  }

  const showModal = ref(false);
  let contact = ref([]);

  let currentConfirmationData = reactive({
    title: translate('do you realy want to apply for this job?', 'ucfirst'),
    text: translate('by applying to this job you will be able to see its contact informations and check it on your dashboard later', 'ucfirst') + `. ${translate('and the company will have access to the informations you added to your profile', 'ucfirst')}`,
    visible: false
  });
  async function tryToApply(){
    if(job.value.job_id == null){
      showToast(toast, translate('invalid action, refresh the page or try again later', 'ucfirst'));
    }
    if(!store.getters.isLoggedIn){
      store.dispatch('saveRouteToGoAfterLogin', {url: `/job/${job.value.job_id}`});
      await requestHandler.redirect('/login');
    }
    if(!store.getters.loggedAsProfessional){
      showToast(toast, translate('you are not logged as a professional, only professionals can apply to jobs', 'ucfirst'), 'info');
      return;
    }
    currentConfirmationData.visible = true;
  }

  let loadingModal = ref(false);
  async function applyToJob(confirmationResponse = false){
    currentConfirmationData.visible = false;
    if(!confirmationResponse.confirmation){
      return;
    }
    loadingModal.value = true;
    const response = await professionalData.applyToJob(job.value.job_id);
    if(!response.success){
      showToast(toast, response.message, 'error');
      loadingModal.value = false;
      return;
    }
    showToast(toast, response.message);
    applianceStatus.canApply = false;
    applianceStatus.appliedAt = response.data.data.applicationDate;
    applianceStatus.contactInformation = {
      contactName: response.data.data.contact_name,
      contactEmail: response.data.data.contact_email,
      contactPhone: response.data.data.contact_phone,
      phoneDDI: response.data.data.ddi,
      contactWebSite: response.data.data.contact_website
    };
    await professionalData.updateMyAppliances(response.data.data.applied_id, response.data.data);
    contact.value = applianceStatus.contactInformation;
    loadingModal.value = false;
    showModal.value = true;
  }

  let applianceStatus = reactive({
    canApply: false,
    appliedAt: '',
    contactInformation: {}
  });
  function checkAppliance(job_id = ''){
    if(store.getters.loggedAsProfessional){
      let myAppliances = store.getters.getOnDataChest('my_appliances');
      if(myAppliances !== null && myAppliances !== undefined && !utils.isEmpty(myAppliances)){
        for(let appliance of myAppliances){
          if(appliance.job_id == job_id){
            applianceStatus.canApply = false;
            applianceStatus.appliedAt = appliance.applicationDate;
            applianceStatus.contactInformation = {
              contactName: appliance.contact_name,
              contactEmail: appliance.contact_email,
              contactPhone: appliance.contact_phone,
              phoneDDI: appliance.ddi,
              contactWebSite: appliance.contact_website
            };
            contact.value = applianceStatus.contactInformation;
            return;
          }
        }
      }
    }
    resetAppliance();
  }

  function resetAppliance(){
    applianceStatus.canApply = true;
    applianceStatus.appliedAt = '';
    applianceStatus.contactInformation = {};
  }

  async function hideManyOptionExcess(){
    let tries = 0;
    let elements = document.querySelectorAll('.an-option');
    while(elements.length < 1){
      elements = document.querySelectorAll('.an-option');
      tries++;
      await sleep(50);
      if(tries == 30)
        break;
    }
    if(elements.length < 1)
      return;
    elements.forEach(el => {
      if(!el.classList.contains('opt-fixed')){
        el.setAttribute('hidden', true);
      }
    });
  }
  
  async function openModal() {
    contact.value = applianceStatus.contactInformation;
    showModal.value = true;
  } 

  let sharerData = ref({
    visible: false,
    link: '',
    title: '',
    description: '',
    hashtags: ''
  });
  function openSharer(){
    if([null,undefined,''].includes(job.value))
      return;
    let textToShare = `${translate('job opportunity', 'capitalize')}:\n`;
    // Company name
    if(!['',null,undefined].includes(job.value.company_name))
      textToShare += `🏢 ${job.value.company_name}\n`;
    // Job location and modality
    textToShare += `📍`;
    if(!['',null,undefined].includes(job.value.job_modality))
      textToShare += ` ${job.value.job_modality} /`;
    textToShare += ` ${job.value.full_location}\n`;
    // Experience required
    if(!['',null,undefined].includes(job.value.experience_required))
      textToShare += `📖 ${job.value.experience_required}\n`;
    // wage
    if(!['',null,undefined,0].includes(job.value.minimunWage) || !['',null,undefined,0].includes(job.value.maxWage))
      textToShare += `💰 ${job.value.salary}\n`;
    // Link
    textToShare += `🔗 + info: ${env('APP_BASE_URL')}job/${job.value.job_id}`;
    sharerData.value.link = `${env('APP_BASE_URL')}job/${job.value.job_id}`;
    sharerData.value.title = textToShare;
    sharerData.value.hashtag = `${translate('job')},${job.value.job_country},${job.value.job_seniority},${job.value.job_modality}`;
    sharerData.value.visible = true;
  }
</script>